import * as React from "react";
import styled from "styled-components";
import { ContactForm, SeoData, ExitRamp } from "components";
import { H2, H3, H4, P, Container, Grid, Button } from "styles";
import { StaticImage } from "gatsby-plugin-image";
import { EmailIcon, MobIcon, LocationIcon } from "images/svgs";
import {motion} from 'framer-motion';

const ContactPage = () => {
  return (
    <motion.main
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ delay:.25, duration: .75 }}
  >
      <SeoData pageSpecificTitle="Contact Us" pageSpecificDescription="For more information about AeroChamber® contact Teva: T: +44 207 540 7117 or E: Medinfo:@tevauk.com" />
      <Container>
        <H2 centered>Contact Us</H2>
        <Grid>
          {/* <ContactItem>
            <ContactForm />
          </ContactItem> */}
          <ContactItem>
            <H3><strong>About Us</strong></H3>
            <StaticImage
              src="../images/teva-logo.png"
              alt="teva Logo"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="contain"
              className="product-image"
              style={{maxWidth: '100px', height: '50px', marginBottom: '1em'}}
            />
            <P>
            Teva Pharmaceuticals Ireland are the distributor of AeroChamber® in Ireland. If you have any questions or queries about the products we would be happy to help you.
            </P>
            <P><a href="https://teva.ie" target="_blank" rel="noreferrer">Visit <strong>Teva.ie</strong> here.</a></P>
 
            <StaticImage
              src="../images/trudell-logo.jpg"
              alt="trudell Logo"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="contain"
              className="product-image"
              style={{maxWidth: '200px', height: '50px', marginBottom: '1em'}}
            />
            <P>
            Trudell are leaders in designing and manufacturing the highest quality aerosol drug delivery and lung health devices for the management of respiratory conditions.
            </P>
            <br/>
            <br/>
            <H3>Contact details:</H3>
            <a href="mailto:info@teva.ie">
              <EmailIcon /> info@teva.ie
            </a>
            <a href="tel: 1800 201 700">
              <MobIcon /> 1800 201 700
            </a>
            {/* <a href="https://goo.gl/maps/zNc1YthVcfuEPhK7A" target="_blank" rel="noreferrer">
              <LocationIcon /> Digital Office Centre Swords, Suite 101 - 103,
              <br />
              Balheary Demense, Balheary Road, Swords, Co Dublin, <br />
              K67E5AO, Ireland
            </a> */}
          </ContactItem>
        </Grid>
      </Container>
     {/*  <Container>
        <Grid>
        <GridItem>
            <StaticImage
              src="../images/life-effects-thumb.jpg"
              alt="Life Effects"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="cover"
              className="product-image"
            />
            <Caption>
              <H4>Teva Life Effects</H4>
              <P>
                Initiative shaped by patients, for patients. Life Effects tips
                and technologies to make things a little easier.
              </P>
              <ExitRamp
                trigger={<Button type="button">Visit Page</Button>}
                modalLink={"https://lifeeffects.teva/eu/"}
              />
            </Caption>
          </GridItem>
          <GridItem>
            <StaticImage
              src="../images/ltr-thumb.jpg"
              alt="Lets Talk Respiratory"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="cover"
              className="product-image"
            />
            <Caption>
              <H4>Let’s Talk Respiratory</H4>
              <P>
                Let's Talk Respiratory is our educational programme of
                supportive materials and some of the latest news in respiratory.
              </P>
              <ExitRamp
                trigger={<Button type="button">Visit Page</Button>}
                modalLink={"https://letstalkrespiratory.ie/"}
              />
            </Caption>
          </GridItem>
        </Grid>
      </Container> */}
    </motion.main>
  );
};

const ContactItem = styled.div`
  display: flex;
  flex-direction: column;
  ${H3} {
    margin-bottom: .5em;
  }
  a {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1em;
    line-height: 1.5em;
    color: var(--primary-color);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    strong{
      margin: 0 .3rem;
    }
    svg {
      height: auto;
      min-width: 1rem;
      width: 1rem;
      max-height: 1rem;
      margin-right: 1rem;
      margin-top: 0.25rem;
      path {
        stroke: var(--primary-color);
      }
    }
  }
  &:nth-child(1) {
    grid-area: 2 / 1 / span 1 / span 7;
  }
  &:nth-child(2) {
    grid-area: 1 / 1 / span 1 / span 7;
    margin-left: 1rem;
  }
  @media (min-width: 768px) {
    &:nth-child(1) {
      grid-area: 1 / 2 /1 / span 5;
    }
    &:nth-child(2) {
      grid-area: 1 / 7 / 1 / span 5;
      margin-left: 1rem;
    }
  }
`;

const Caption = styled.div`
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  padding: 1.5rem;
  ${P} {
    line-height: 1.15em !important;
    font-size: 1rem !important;
    @media (min-width: 1150px) {
      line-height: 1.15em !important;
      font-size: 1rem !important;
    }
  }
  ${Button} {
    background: none;
    margin: 0;
    color: var(--primary-color);
    text-decoration: none;
    font-size: 0.875rem;
    font-family: "Helvetica Neue";
    font-weight: 700;
    padding:0;
  }
`;

const GridItem = styled.div`
  display: grid;
  grid-gap: 0;
  grid-column: 1/7;
  grid-template-columns: 1fr;
  .gatsby-image-wrapper {
    max-width: 100%;
    width: 100% !important;
    height: auto !important;
    padding: 3.5rem;
  }
  @media (min-width: 768px) {
    &:nth-child(1) {
      grid-column: 2 / span 5;
    }
    &:nth-child(2) {
      grid-column: 7 / span 5;
    }
  }
  @media (min-width: 1150px) {
    grid-template-columns: 2fr 3fr;
    .gatsby-image-wrapper {
      padding: 0;
    }
  }
`;

export default ContactPage;
